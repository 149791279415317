import { CloseButtonTypes, Modal, Text } from 'fitify-ui'
import { FitifyLogoTemporaryIcon } from 'fitify-ui/src/Icon/logo'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import { ContentRevealAnimation } from 'fitify-ui-landing/src/components/animations/ContentRevealAnimation'
import Button from 'fitify-ui-landing/src/components/Button/Button'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { faqData } from '@/data/faq'

import { StyledFaq } from './Faq.Styled'
import FaqList from './FaqList'
import FaqModalContent from './FaqModalContent'

const Faq = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleCloseModal = () => {
    setShowModal(false)
  }
  return (
    <Section id={ELEMENT_IDS.frequentlyAskedQuestions}>
      <Container>
        <ContentRevealAnimation>
          <StyledFaq>
            <div>
              <Text
                as={'h2'}
                variant={'faq-title'}
                html={t('hc_landing_faq_block_headline')}
              />
            </div>
            <div>
              <FaqList items={faqData.slice(0, 4)} />
              <Button
                variant="primary-black"
                onClick={() => setShowModal(true)}
              >
                {t('hc_landing_faq_block_view_all_button')}
              </Button>
              <Modal
                show={showModal}
                handleClose={handleCloseModal}
                options={{
                  title: (
                    <Text
                      as="h3"
                      variant="modal-title"
                      fontWeight={500}
                      fontFamily="Graphik Wide Web"
                    >
                      {t('hc_landing_faq_block_headline')}
                    </Text>
                  ),
                  logo: <FitifyLogoTemporaryIcon />,
                }}
                config={{
                  closeVariant: CloseButtonTypes.WHITE,
                }}
              >
                <FaqModalContent />
              </Modal>
            </div>
          </StyledFaq>
        </ContentRevealAnimation>
      </Container>
    </Section>
  )
}

export default Faq
